import React from "react"
import { format, parseISO } from "date-fns"

import { Title1Declaration } from "@/components/lib"
import { convertToData } from "@/clients/declarations"
import { useDeclarationForm } from "@/hooks/useDeclarationContext"
import { DeclarationTypePart, FactsPart, FinalPrecisionsPart, ReasonsPart, VictimsAuthorsPart } from "@/pages/declaration/[id]"
import { DeclarationModel } from "@/models/declarations"

const DatePart = ({ data }: { data: DeclarationModel }) => {
  const location = data.location
  const formattedLocation = (key: string) => {
    if (location) {
      if (Array.isArray(location[key]) && location[key]?.[0] === "Autre")
        return `${location[key]?.[0]} (${location[key]?.[1]})`
      else if (Array.isArray(location[key]))
        return location[key]?.map((val: string, index: number) =>
          index === 0 ? `${val} ` : ` ${val} `,
        )
      else return location[key]
    }
  }
  const currentDate = new Date().toLocaleDateString('fr-FR', {day: '2-digit', month: '2-digit', year: 'numeric'}).split('/').join('/')

  return (
    <>
      <Title1Declaration className="mt-6 mb-4 text-left">
        <b>Date & lieu</b>
      </Title1Declaration>
      <p>
        <span className="inline-block w-48 font-bold">
          Date de la déclaration
        </span>
        {currentDate}
      </p>
      {data.job && (
        <p>
          <span className="inline-block w-48 font-bold">
            Profession du déclarant
          </span>
          {data.job}
        </p>
      )}
      <p>
        <span className="inline-block w-48 font-bold">
          {"Date de l'évènement"}
        </span>
        {format(parseISO(data.date as unknown as string), "dd/MM/yyyy")}
      </p>
      <p>
        <span className="inline-block w-48 font-bold">Horaire</span>
        {data.hour}
      </p>
      <p>
        <span className="inline-block w-48 font-bold">Ville</span>
        {data.town}
      </p>
      <p>
        <span className="inline-block w-48 font-bold">Code postal</span>
        {data.postalCode}
      </p>
      {location &&
        Object.keys(location)?.map((key) => (
          <p key={key}>
            <span className="inline-block w-48 font-bold">{key}</span>
            {formattedLocation(key)}
          </p>
        ))}
    </>
  )
}

const PreviewDeclaration = (): JSX.Element => {

  const { state, orderedSteps } = useDeclarationForm()

  const data = convertToData(
    state,
    orderedSteps.map((step) => step.name),
  )

  return (
    <>
      <div
        className="px-16 py-4 m-auto mb-8 shadow-xl max-w-xxl"
        style={{ maxWidth: 800 }}
      >
        <Title1Declaration className="mt-12">
          <b>{"Votre déclaration"}</b>
        </Title1Declaration>

        {data && (
          <>
            <DeclarationTypePart data={data} />
            <DatePart data={data} />
            <FactsPart data={data} />
            <ReasonsPart data={data} />
            <VictimsAuthorsPart data={data} />
            <FinalPrecisionsPart data={data} />
          </>
        )}
      </div>
    </>
  )
}

export default PreviewDeclaration


import Link from "next/link"
import React, { useEffect, useState } from "react"

import {
  convertToData,
  createDeclaration,
  findDeclaration,
} from "@/clients/declarations"
import { Layout } from "@/components/layout/Layout"
import { PrimaryButton, OutlineButton, Title1 } from "@/components/lib"
import { useDeclarationForm } from "@/hooks/useDeclarationContext"
import HeadTitle from "@/components/HeadTitle"
import { getDeclarationPDF } from "@/utils/declarationPDF"
import { useSession } from "next-auth/react"
import { cleanSecureToken } from "@/utils/secureTokenHelper"

const Confirmation = () => {
  const { data: session } = useSession()

  const { state, orderedSteps } = useDeclarationForm()

  const [error, setError] = useState()
  const [warning, setWarning] = useState()

  async function getPDF() {
    state.declarationType === "ets"
      ? getDeclarationPDF(await findDeclaration(state.id))
      : getDeclarationPDF(
          convertToData(
            state,
            orderedSteps.map((step) => step.name),
          ),
        )
  }

  useEffect(() => {
    const create = async (declaration) => {
      try {
        await createDeclaration({
          declaration,
          keys: orderedSteps.map((step) => step.name),
        })
        cleanSecureToken()
      } catch (error) {
        console.error(error)

        if (error.statusCode === 409) {
          setWarning({
            emoji: "",
            message: "Il semble que la déclaration soit déjà enregistrée.",
          })
        } else
          setError({
            emoji: "",
            message:
              "Malheureusement, la déclaration n'a pas pu être enregistrée.",
          })
      }
    }
    create(state)
  }, [state, orderedSteps])

  return (
    <Layout>
      <HeadTitle title="Déclaration - Confirmation d'enregistrement" />

      <div className="max-w-4xl m-auto mb-8">
        {error && (
          <Title1 className="mt-12">
            <b>
              {
                "Votre déclaration d'incident de violence n'a pas pu être enregistrée."
              }
            </b>
          </Title1>
        )}
        {!error && (
          <>
            <Title1 className="mt-12">
              {warning && (
                <b>Il semble que la déclaration soit déjà enregistrée.</b>
              )}
              {!warning && (
                <b>
                  {
                    "Votre déclaration d'incident de violence a bien été enregistrée."
                  }
                </b>
              )}
            </Title1>

            <div className="max-w-2xl m-auto">
              {warning && (
                <p className="mt-4 text-center">
                  {
                    "Il n'est plus possible de modifier la déclaration une fois qu'elle a été confirmée."
                  }
                </p>
              )}
              {session ? (
                <>
                  <p className="my-12 text-center">
                    Elle est disponible en téléchargement. Vous pouvez vous
                    munir de ce document lors du dépôt de plainte afin de vous
                    aider à reconstituer les faits de manière précise.
                  </p>
                </>
              ) : (
                <>
                  <p className="mt-16 text-center">
                    L&apos;ONVS et l&apos;ordre professionnel de santé auquel
                    vous êtes inscrit si vous y êtes soumis, y auront accès.
                  </p>
                  <p className="mt-2 text-center">
                    Attention: votre déclaration n&apos;est pas une plainte
                    juridique et n&apos;est pas non plus une pré-plainte en
                    ligne.
                  </p>
                </>
              )}
              <div className="flex justify-center w-full my-6 space-x-4">
                <PrimaryButton onClick={getPDF}>
                  Télécharger votre déclaration
                </PrimaryButton>
              </div>
              {!session && (
                <>
                  <p className="mt-2 text-center">
                    Vous pouvez vous munir de ce document lors du dépôt de
                    plainte afin de vous aider à déclarer les faits:
                  </p>
                  <p className="mt-2 text-center">
                    aux services de la police nationale,
                  </p>
                  <p className="mt-2 text-center">
                    aux unités de la gendarmerie nationale,
                  </p>
                  <p className="mt-2 text-center">
                    ou par courrier au procureur de la République
                  </p>
                </>
              )}
            </div>
          </>
        )}
        <div className="flex justify-center w-full my-16 space-x-4">
          {session ? (
            <Link href="/private">
              <OutlineButton>Retour au tableau de bord</OutlineButton>
            </Link>
          ) : (
            <Link href="/">
              <OutlineButton>Déclarer un autre incident</OutlineButton>
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Confirmation


import React from "react"

import FormComponent from "@/components/wizard/FormComponent"
import { useDeclarationForm } from "@/hooks/useDeclarationContext"
import PreviewDeclaration from "../../../../components/PreviewDeclaration"

const Step6Page = () => {
  const { onSubmit, handleSubmit } = useDeclarationForm()

  return (
    <FormComponent
      onSubmit={handleSubmit(onSubmit)}
      title="Aperçue déclaration"
    >
      <PreviewDeclaration />

      <p className="mt-10 text-md font-bold">
        Attention ! Une fois envoyée, votre déclaration ne sera plus modifiable.
      </p>
    </FormComponent>
  )
}

export default Step6Page

